body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	justify-content: center;
	margin: 0;
	background-color: #f3efeb;
	font-weight: 400;
	/*overflow: hidden; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
	font-family: "Supply-Regular";
	src: url("fonts/Supply-Regular.otf");
}
@font-face {
	font-family: "Supply-Light";
	src: url("fonts/Supply-Light.otf");
}
* {
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar {
	width: 0;
}

* {
	box-sizing: border-box;
}
