html {
  height: -webkit-fill-available;
}

body, #root, .App{
  height: 100vh;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
}
@supports (-webkit-touch-callout: none) {
  html, body, #root, .App {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
.App {
   background-color: #F3EFEB;
   padding-top: 16px;
   max-width: min(100vw, 415px);
   width: 100vw;
   font-family: 'Supply-Regular'; 
   font-weight: 400;
   position: relative;
   font-size: 14px; 
   box-sizing: border-box;
}
.App-hr{
   max-width: 134px;
   width: 100%;
   height: 5px;
   position: absolute;
   bottom: 8px;
   background-color: black;
   left: 50%;
   transform: translateX(-50%);
   border-radius: 50px;
}
.shuffle{
   background-color: #F3EFEB;
   width: min(100vw - 32px, 415px - 32px);
   height: 46px;
   border-radius: 4px;
   color: #706E52;
   font-size: 18px;
   position: fixed;
   bottom: 70px;
   left: 50%;
   display: block;
   text-decoration: none;
   box-sizing: border-box;
   border: 1px solid #706E52;
   line-height: 46px;
   transform: translateX(-50%);
   font-family: 'Supply-Regular';     
}
.go-Next{
   background-color: #706E52;
   width: min(100vw - 32px, 415px - 32px);
   height: 46px;
   border-radius: 4px;
   color: white;
   font-size: 18px;
   position: fixed;
   bottom: 16px;
   left: 50%;
   display: block;
   text-decoration: none;
   line-height: 46px;
   transform: translateX(-50%);
   border: unset;
   font-family: 'Supply-Regular'; 
}
.go-Next:disabled {
   background-color: #706E52;
   opacity: 0.3;
}
.go-Back{
   position: absolute;
   left: 16px;
   cursor: pointer;
}
.img{
   max-width: min(345px, 100%);
   max-height: 250px;
}
.span{
   color: #613C4B;
}
@media(max-height: 700px){
    .App-hr{
        position: relative;
    }
}
button{
    cursor: pointer;
}


/*Home page*/
.StartPage-body{
    text-align: center;
    padding: 32px 16px 62px;
    display: flex;
    height: calc(100% - 62px);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.StartPage-title{
    font-size: 48px;
    line-height: normal; 
    font-weight: normal;
}

.StartPage-text{
    font-size: 18px;
}

.StartPage-img{
    margin: 4.5vh 0;
    line-height: normal; 
}

/* Time selection*/
.Time-body{
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
    padding: 0 16px 16px;
    text-align: center;
}

.Time-selection{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Time-title{
    margin-bottom: 24px;
    text-align: center;
}

.Time-input{
    display: none;
}
.Time-label::after{
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-image: url('./../../img/picker.svg');
    background-size: 18px 18px;
    display: block;
}

.Time-label{
    display: flex;
    justify-content: space-between;
    border: rgba(80, 80, 80, 0.3) 1px solid;
    padding: 12px 20px;
    border-radius: 4px;
}
.Time-input:checked + .Time-label::after{
    background-image: url('./../../img/picker-checked.svg');
}
.Time-image{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*Focus selection*/
.Focus-body{
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
    padding: 0 16px 16px;
    text-align: center;
}
.Focus-title{
    text-align: center;
}
.Focus-small_title{
    color: rgba(0, 0, 0, 0.60); 
    font-size: 18px; 
    margin-top: 24px;
    margin-bottom: 12px;
    text-align: left;
}
.Focus-input{
    display: none;
}
.Focus-label{
    display: flex;
    justify-content: space-between;
    border: rgba(80, 80, 80, 0.3) 1px solid;
    padding: 8px 24px;
    border-radius: 4px;
}
.Focus-selection{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.Focus-input:checked + .Focus-label{
    background-color: #706E52;
    color: white;
}
.Focus-image{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    
}
/* Goal Selection*/
.Goal-body{
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
    padding: 0 16px 16px;
    text-align: center;
}
.Goal-title{
    text-align: center;
    margin-bottom: 8px;
}
.Goal-title_small{
    color: rgba(0, 0, 0, 0.60); 
    font-weight: 300; 
    font-size: 14px; 
    font-family: 'Supply-Light';
    margin-bottom: 24px;
}
.Goal-span_small{
    color:black;
    font-weight: 400; 
    font-family: 'Supply-Regular';
}

.Goal-selection{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Goal-input{
    display: none;
}
.Goal-label::after{
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-image: url('./../../img/picker.svg');
    background-size: 18px 18px;
    display: block;
}

.Goal-label{
    display: flex;
    justify-content: space-between;
    border: rgba(80, 80, 80, 0.3) 1px solid;
    padding: 12px 20px;
    border-radius: 4px;
}
.Goal-input:checked + .Goal-label::after{
    background-image: url('./../../img/picker-checked.svg');
}
.Goal-image{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*Vibe selection*/
.Vibe-body{
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
    padding: 0 16px 16px;
    text-align: center;
}
.Vibe-title{
    text-align: center;
    margin-bottom: 24px;
}

.Vibe-selection{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Vibe-input{
    display: none;
}
.Vibe-label::after{
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-image: url('./../../img/picker.svg');
    background-size: 18px 18px;
    display: block;
    background-repeat: no-repeat;
}

.Vibe-label{
    display: flex;
    justify-content: space-between;
    border: rgba(80, 80, 80, 0.3) 1px solid;
    padding: 12px 20px;
    border-radius: 4px;
    align-items: center;
}
.Vibe-input:checked + .Vibe-label::after{
    background-image: url('./../../img/picker-checked.svg');
}
.Vibe-label p {
    max-width: 90%;
    text-align: left;
}
.Vibe-image{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*Workout list*/
.Workout-body{
    padding-top: 0;
	padding-left: 16px;
	padding-right: 16px;
    padding-bottom: 140px;
}
.Workout-title{
    text-align: center;
    margin-bottom: 8px;
}
.Workout-title_small{
    margin-top: 32px;
    margin-bottom: 16px;
}
.Workout-item, .Workout-item a{
    display: flex;
    gap: 16px;
    align-items: flex-start;
    color: inherit;
    text-decoration: none;
    width: 100%;
}
.Workout-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Workout__superset-title{
    font-size: 16px;
    color: #000;
    line-height: 120%;
    margin-top: 16px;
    margin-bottom: 16px;
}
.Workout__superset-list{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Workout-name{
    font-size: 16px; 
    line-height: normal; 
    text-transform: capitalize;
}
.Exercises-image{
    width: 80px;
}
.Workout-exercise, .Workout-tempo{
    color: rgba(80, 80, 80, 0.50); 
    font-size: 14px; 
    line-height: 140%; 
}
.Workout-arrow{
    width: 12px;
    height: 12px;
}
.Workout-button{
    border: none;
    background: none;
}

.Exercises-body {
	padding-top: 0;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	padding-left: 16px;
	padding-right: 16px;
    padding-bottom: 94px;
}
.Exercises-bigtitle{
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    font-weight: 400;
}
.Exercises-title{
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    font-weight: 400;    
}
.Exercises-result{
    text-align: center;
}
.Exercises-clock {
	font-size: 57px;
	line-height: 64px;
	color: #fff;
}
.Exercises-body_fullbody{
	gap: 4px;
}
.Exercises-clock_fullbody {
	font-size: 57px;
	line-height: 64px;
	color: #fff;
}
.Exercises-clock_fullbody{
	margin-bottom: 12px;
}
.Exercises-time,
.Exercises-minutes {
	background-color: #706e52;
	border-radius: 4px;
	padding: 10px;
}
.Exercises-dot {
	color: #706e52;
}
.Exercises-title_fullbody{
	margin-top: 32px;
	font-size: 20px;
	align-self: start;
}
.Exercises-title_description{
	font-size: 14px;
	line-height: 140%;
	color: rgba(80, 80, 80, 0.5);
	width: 100%;
}
.Exercises-title_small {
	margin-top: 32px;
	margin-bottom: 16px;
	font-size: 20px;
	align-self: start;
}
.Exercises-item {
	display: flex;
	gap: 16px;
	align-items: flex-start;
}
.Exercises-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
    width: 100%;
}
.Exercises-container a, .Exercises-no_link {
	display: flex;
	gap: 16px;
	align-items: flex-start;
	color: inherit;
	text-decoration: none;
	width: 100%;
}
.Exercises-right{
	gap: 4px;
	display: flex;
	flex-direction: column;
}
.Exercises-name {
	font-size: 16px;
	line-height: normal;
	text-transform: capitalize;
}
.Exercises-complete{
	font-size: 14px;
	color: rgba(112, 110, 82, 1);
	line-height: 20px;
}
.Exercises-image{
    width: 80px;
}
.Exercises-exercise,
.Exercises-tempo {
	color: rgba(80, 80, 80, 0.5);
	font-size: 14px;
	line-height: 140%;
}
.Exercises-arrow {
	width: 12px;
	height: 12px;
	align-self: center;
}
.Exercises-button {
	border: none;
	background: none;
}
.Exercises-state{
	display: flex;
    margin-left: auto;
    align-self: center;
}
.Exercises-status{
	width: 32px;
	height: 32px;
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Exercises-progress{
	--v:calc( ((18/5) * var(--p) - 90)*1deg);

	width:32px;
	height:32px;
	display:inline-block;
	border-radius:50%;
	padding:2px;
	background:
		linear-gradient(var(--v), #cdcdcd 50%,transparent 0) 0/min(100%,(50 - var(--p))*100%),
		linear-gradient(var(--v), transparent 50%,#706e52 0) 0/min(100%,(var(--p) - 50)*100%),
		linear-gradient(to right, #cdcdcd 50%,#706e52 0);
	-webkit-mask:
		linear-gradient(#fff 0 0) content-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite:destination-out;
	mask-composite:exclude;
}
.Exercises-progress+img{
	position: absolute;
    left: 10px;
    top: 11px;
}
.Exercise-comment {
	display: flex;
	gap: 16px;
	margin-top: 40px;
	align-items: flex-start;
}
.Exercise-comment-value{
	font-size: 14px;
	line-height: 140%;
	font-weight: 300;
	color: #000;
}
.Exercises-item_rest{
	margin-left: 96px;
	color: rgba(161, 159, 157, 1);
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
}
.Exercises-item_rest::before{
	content: "";
	width: 28px;
	height: 1px;
	background-color: rgba(161, 159, 157, 0.5);
}
.Exercises-item_rest::after{
	content: "";
	width: 28px;
	height: 1px;
	background-color: rgba(161, 159, 157, 0.5);	
}


.Item-body{
    padding: 16px 16px 94px;
  
}
.Item-video{
    width: 100vw;
    height: 240px;
    margin-top: -16px;
    max-width: 100%;
}
.Item-title{
    font-size: 24px; 
    margin-bottom: 10px;
    text-transform: capitalize;
}
.Item-tempo{
    color: rgba(80, 80, 80, 0.50); 
}
.Item-description{
    color: #505050;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 16px 0;
}
.Item-top{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.Item-timeOut{
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
}
.Item-timeOut::before{
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    background-image: url('/src/img/clock.svg');
    background-repeat: no-repeat;
}
.Item-process{
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 7px 16px;
    margin-top: 7px;
    align-items: center;
}
.Item-step span{
    background-color: #C2BFBC;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    width: 32px;
    height: 32px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
.Item-reps{
    font-size: 18px; 
    line-height: 24px; 
    color: #C2BFBC;
}
.Item-Zero{
    background: #C2BFBC;
    height: 40px;
    width: 2px;
    justify-self: center;
}
.Item-rest{
    display: flex;
    justify-content: center;
    font-size: 16px; 
    line-height: 24px;
}
.Item-rest:before{
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background-image: url('/src/img/clock-gray.svg');
    background-repeat: no-repeat;
    background-size: 24px 24px;
}

.Item-rest-time {
    display: flex;
    justify-content: space-between;
    color: #C2BFBC;
   }
 .Item-rest.active:before{
    background-image: url('/src/img/time-step.svg');
}


  .Item-rest-time.active{
    color: #000000;
   }
.Item-step.active span{
    background-color: #706E52;
}

.Item-timeOut.active::before{
    background-image: url('/src/img/clock.svg');

}
.Item-reps.active{
    font-size: 18px; 
    color: #000000;
}

.Cardio-body {
	padding: 32px 16px 78px;
	gap: 32px;
	display: flex;
	flex-direction: column;
}
.Cardio-video {
	width: 100vw;
	height: 240px;
	margin-top: -16px;
	max-width: 377px;
}
.Cardio-title {
	font-size: 24px;
}
.Cardio-top_no-video{
	padding-top: 32px;
}
.Cardio-top-title{
	display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
	text-transform: capitalize;
}
.Cardio-tempo {
	color: rgba(80, 80, 80, 0.5);
}
.Cardio-equipment_title,
.Cardio-tempo_title,
.Cardio-rest_title {
	font-size: 18px;
	margin-bottom: 4px;
}
.Cardio-equipment_text,
.Cardio-tempo_text,
.Cardio-rest_text {
	font-weight: 300;
	line-height: 140%;
	font-family: "Supply-Light";
}

.Cardio-rest-timer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #706e52;
	position: fixed;
	width: min(100vw, 415px);
	bottom: 0;
	height: 262px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.Cardio-rest-timer-top {
}
.Cardio-rest-timer-top-title {
	text-align: center;
	color: #fff;
	font-size: 32px;
	font-weight: 400;
	line-height: 38.4px;
}
.Cardio-rest-timer-top-timer {
	padding-top: 12px;
	color: #fff;
	font-size: 48px;
	font-weight: 400;
	line-height: 57.6px;
}
.Cardio-rest-timer-buttons {
	display: flex;
	flex-direction: row;
	gap: 8px;
	padding-top: 12px;
}

.Cardio-rest-timer-button {
	font-family: "Supply-Regular";
	border-radius: 4px;
	padding: 8px 12px;
	border: unset;
	display: flex;
	align-items: center;
	gap: 4px;
}

.Cardio-rest-timer-end {
	background-color: #c2bfbc;
}

.Cardio-finish img{
    max-height: 352px;
}